<template>
  <div class="contact">
    <Header :isBarWhiteStyle="true"></Header>
    <div class="banner">
      <img
        class="banner-bg"
        src="@/assets/img/pc/productDownload/top-bg.png"
      >
      <div class="banner-box width_fixed">
        <h2>易借速贷APP</h2>
        <div></div>
        <p>
          易借速贷是浙江未讯科技有限公司旗下信贷服务品牌，
          <br />
          基于人工智能和大数据风控应用能力，与银行等金融机构伙伴合作，
          <br /> 提供面向大众的个人信贷服务，具有申请简便、审批快、额度高、
          <br />息费低、到账快等特点。易借速贷能够根据用户的需求进行精准匹配，
          <br /> 致力于成为受用户欢迎，受伙伴信任的金融科技平台。
        </p>
      </div>
    </div>
    <main class="about-us width_fixed ">
      <div class="process">
        <h1 class="contact-title">借款流程</h1>
        <div class="process-pg">
          <div
            class="process-pg-item"
            v-for="(item,index) in processList"
            :key="index"
          >
            <div>
              <img
                class="process-pg-item-pg"
                :src="item.pgIcon"
              >
            </div>
            <h2>{{item.title}}</h2>
            <h3>{{item.subTitle}}</h3>
            <img
              class="process-pg-item-img"
              :src="item.img"
            >
          </div>
          <div class="process-pg-line"></div>
        </div>
      </div>
      <div class="advantage">
        <h1 class="contact-title">产品优势</h1>
        <ul>
          <li
            v-for="(item, index) in productAdvantage.list"
            :key="index"
          >
            <img :src="item.imgUrl">
            <div class="content">
              <h2>{{item.title1}}</h2>
              <div class="advantage-line"></div>
              <p>{{item.title2}}</p>
            </div>
          </li>
        </ul>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/pc/header'
import Footer from '@/components/pc/footer'

export default {
  data () {
    return {
      processList: [
        {
          pgIcon: require('@/assets/img/pc/productDownload/pg1.png'),
          title: '打开App',
          subTitle: 'APP首页点击“去借钱”',
          img: require('@/assets/img/pc/productDownload/img1.png')
        }, {
          pgIcon: require('@/assets/img/pc/productDownload/pg2.png'),
          title: '查看额度',
          subTitle: '身份认证获取额度',
          img: require('@/assets/img/pc/productDownload/img2.png')
        }, {
          pgIcon: require('@/assets/img/pc/productDownload/pg3.png'),
          title: '借款到账',
          subTitle: '借款成功转至银行卡',
          img: require('@/assets/img/pc/productDownload/img3.png')
        }
      ],
      productAdvantage: {
        list: [
          { imgUrl: require('@/assets/img/pc/productDownload/product_bg1.png'), title1: '额度高', title2: '最高可借200000元' },
          { imgUrl: require('@/assets/img/pc/productDownload/product_bg2.png'), title1: '放款快', title2: '自动审批，最快10秒钟' },
          { imgUrl: require('@/assets/img/pc/productDownload/product_bg3.png'), title1: '息费低', title2: '符合国家标准，还款灵活' }
        ]
      }
    }
  },
  watch: {},
  mounted () { },
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="less">
.contact {
  .banner {
    padding-top: 80px;
    position: relative;
    .banner-bg {
      left: 0;
      top: 0;
      width: 100%;
      min-width: 1300px;
      height: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .banner-box {
      width: 1300px;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      h2 {
        font-size: 30px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #1e1f20;
        line-height: 40px;
        letter-spacing: 2px;
      }
      div {
        width: 60px;
        height: 3px;
        background: #1e1f20;
        margin-top: 23px;
        margin-bottom: 30px;
      }
      p {
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 30px;
      }
    }
  }
  .process {
    width: 1022px;
    margin: 0px auto;
    &-pg {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      position: relative;
      &-line {
        position: absolute;
        top: 18.5px;
        left: 50%;
        transform: translateX(-50%);
        width: 800px;
        height: 1px;
        background-color: #ee6c28;
        opacity: 0.78;
        z-index: -1;
      }
      &-item {
        div {
          display: flex;
          justify-content: center;
        }
        &-pg {
          width: 37px;
          height: 37px;
        }
        h2 {
          margin-top: 20px;
          font-size: 22px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: center;
          color: #1e1f20;
          line-height: 26px;
        }
        h3 {
          margin-top: 9px;
          font-size: 16px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: center;
          color: #4f4f4f;
          line-height: 21px;
        }
        &-img {
          margin-top: 32px;
          width: 224px;
          height: 380px;
        }
      }
    }
  }

  .advantage {
    margin-top: 80px;
    ul {
      display: flex;
      width: 1194px;
      margin: 0px auto;
      margin-top: 41px;
      justify-content: space-between;
      li {
        flex: 1;
        position: relative;
        img {
          margin: 0 auto;
          width: 398px;
          height: 444px;
          float: left;
        }
        .content {
          color: #1e1f20;
          position: absolute;
          top: 0px;
          padding: 85px 67px 0px;
          h2 {
            font-size: 30px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
            font-weight: 700;
            color: #1e1f20;
            line-height: 40px;
          }
          p {
            margin-top: 14px;
            font-size: 20px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #1e1f20;
            line-height: 26px;
          }
        }
      }
    }
  }
  .contact-title {
    font-size: 34px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    font-weight: 400;
    text-align: center;
    color: #1e1f20;
    line-height: 45px;
  }
  .about-us {
    overflow: hidden;
    padding-top: 70px;
    padding-bottom: 109px;
  }
}
</style>
