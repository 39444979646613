<template>
  <footer>
    <div class="footer-main width_fixed">
      <div class="footer-left">
        <dl>
          <dt>联系我们</dt>
          <dd>客服电话：400-777-8802 (9:00-21:00)</dd>
          <dd>投诉电话：0571-87608950 (工作日 9:00-18:00)</dd>
          <dd>商务合作：17706521236</dd>
          <dd>媒体联系：15669948658</dd>
        </dl>
      </div>
      <!-- <div class="footer-right">
        <p>官方微信公众号</p>
        <img
          src="@/assets/img/pc/qrCode.jpeg"
        >
      </div> -->
    </div>
    <div class="footer-line"></div>
    <div class="footer-bottom">
      <span>Copyright ©2018 浙江未讯科技有限公司<a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
        > 浙ICP备18045556号</a></span>
      <div class="footer-left-tips">
        <div>金融服务将根据您的个人情况由适合的正规金融机构提供；</div>
        <div>综合年化利率以最终审批结果为准；平台承诺不额外向用户收取费用；</div>
        <div>借款实际到账时间（提交借款申请到资金到账时间）视机构放款速度而定；请根据个人能力合理贷款，理性消费，避免逾期。</div>
      </div>

    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="less">
footer {
  background: #2c3143;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1300px;
  .footer-main {
    padding: 60px 0px 49px 0px;
    display: flex;
    justify-content: space-between;
    .footer-left {
      display: flex;
      justify-content: space-between;
      dl {
        padding-right: 60px;
        dt {
          font-size: 18px;
          color: #fff;
          line-height: 24px;
          margin-bottom: 20px;
          font-weight: 700;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        }
        dd {
          margin-top: 10px;
          font-size: 16px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
        }
      }
    }
    .footer-right {
      margin-right: 26px;
      p {
        font-size: 18px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 24px;
      }
      img {
        margin-top: 21px;
        width: 124px;
        height: 124px;
      }
    }
  }
  .footer-line {
    height: 1px;
    transform: scaleY(0.5);
    width: 1300px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .footer-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left a:hover,
    a:visited,
    a:link,
    a:active {
      opacity: 0.5;
      font-size: 13px;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 17px;
    }
  }
  .footer-left-tips {
    div {
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
</style>
