<template>
  <header id='header'>
    <div class="header-main width_fixed">
      <h1
        class="logo"
        @click="goHome"
      >
        <router-link to="/home">
          <img :src="isBarWhiteStyle?require('@/assets/img/pc/logo_black.png'):require('@/assets/img/pc/logo.png')">
        </router-link>
        <p id="phone-number">客服热线：400-777-8802</p>
      </h1>

      <ul class="nav">
        <router-link
          to="/"
          tag="li"
          :class="{'active':this.$route.name=='home'&&!isBarWhiteStyle,'menu-white':this.$route.name=='home'&&isBarWhiteStyle,'menu-li-white':isBarWhiteStyle}"
        >首页</router-link>
        <router-link
          to="/helpcenter"
          tag="li"
          :class="{'active':this.$route.name=='helpcenter'&&!isBarWhiteStyle,'menu-white':this.$route.name=='helpcenter'&&isBarWhiteStyle,'menu-li-white':isBarWhiteStyle}"
        >帮助中心</router-link>
        <router-link
          to="/productdownload"
          tag="li"
          :class="{'active':this.$route.name=='productdownload'&&!isBarWhiteStyle,'menu-white':this.$route.name=='productdownload'&&isBarWhiteStyle,'menu-li-white':isBarWhiteStyle}"
        >旗下产品</router-link>
        <router-link
          to="/newsinfo"
          tag="li"
          :class="{'active':this.$route.name=='newsinfo'&&!isBarWhiteStyle,'menu-white':this.$route.name=='newsinfo'&&isBarWhiteStyle,'menu-li-white':isBarWhiteStyle}"
        >媒体报道</router-link>
        <router-link
          to="/about"
          tag="li"
          :class="{'active':this.$route.name=='about'&&!isBarWhiteStyle,'menu-white':this.$route.name=='about'&&isBarWhiteStyle,'menu-li-white':isBarWhiteStyle}"
        >关于我们</router-link>
        <router-link
          to="/contactus"
          tag="li"
          :class="{'active':this.$route.name=='contactus'&&!isBarWhiteStyle,'menu-white':this.$route.name=='contactus'&&isBarWhiteStyle,'menu-li-white':isBarWhiteStyle}"
        >联系我们</router-link>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  data () {
    return {
      active: 'home'
    }
  },
  props: {
    isBarWhiteStyle: {
      type: Boolean,
      default () {
        return false

      }
    }
  },
  mounted () {
    let header = document.getElementById('header')
    let phoneNumber = document.getElementById('phone-number')
    if (this.isBarWhiteStyle) {
      header.style.background = 'white'
      phoneNumber.style.color = '#1e1f20'
    } else {
      header.style.background = 'linear-gradient(180deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.14))'
      phoneNumber.style.color = '#fff'
    }

  },
  methods: {
    goHome () {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less">
header {
  width: 100%;
  min-width: 1300px;
  position: absolute;
  z-index: 996;
  // background-color: rgba(0, 0, 0, 0.2);

  .header-main {
    height: 88px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 187px;
      }
      p {
        margin-left: 59px;
        height: 28px;
        font-size: 17px;
        color: white;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;
      li {
        margin-left: 20px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        padding: 0 12px;
        line-height: 21px;
        font-weight: 400;
        transition: all 0.4s;
        position: relative;
        cursor: pointer;
        font-size: 16px;
        height: 28px;
        color: rgba(256, 256, 256, 0.7);
        // color: red;
      }
      .menu-li-white {
        color: rgba(30, 31, 32, 0.6);
      }
      .menu-white {
        color: rgba(30, 31, 32, 1);
      }
      .active {
        color: white;
      }
      .active::after .menu-white::after {
        content: "";
        display: block;
        width: 80%;
        left: 0;
        bottom: 0;
        margin-left: 5px;
      }
    }
  }
}
</style>
